import Cloudinary from 'cloudinary-core';
import _ from 'lodash';

const cloudinary = Cloudinary.Cloudinary.new();
cloudinary.config({
  cloud_name: process.env.GATSBY_CLOUDINARY_CLOUD_NAME,
  secure: true,
});

const generateFluidImage = (
  maxWidth,
  imageURL,
  quality = 88,
  imageWidth = 1280,
  imageHeight = 805
) => {
  const aspectRatio = imageWidth / imageHeight;
  const sizes = `(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`;
  const src = `${imageURL}?w=${maxWidth}&q=${quality}`;
  const widthRatiosArray = [0.25, 0.5];
  let widthRatio = 1;
  while (maxWidth * widthRatio < imageWidth) {
    widthRatiosArray.push(widthRatio);
    widthRatio += 0.5;
  }
  let srcSet = '';
  widthRatiosArray.forEach(r => {
    const width = Math.ceil(r * maxWidth);
    srcSet += `${imageURL}?w=${width}&h=${Math.ceil(
      width / aspectRatio
    )}&q=${quality} ${width}w,`;
  });
  srcSet += `${imageURL}?w=${imageWidth}&h=${imageHeight}&q=${quality} ${imageWidth}w`;
  const base64 = `https:${imageURL}?w=${10}&h=${10}&q=10`;
  return {
    aspectRatio,
    src,
    srcSet,
    sizes,
    base64,
  };
};

// Cloudinary
// Generate cloudinary image object
const generateCloudinaryUrl = (
  public_id,
  quality,
  width,
  gravity = 'auto',
  addWaterMark = false
) => {
  const commonTransformationParam = {
    width,
    quality,
    crop: 'fill',
  };
  let cloudinaryURL = cloudinary.url(public_id, {
    transformation: [
      {
        ...commonTransformationParam,
        gravity: gravity,
      },
      {
        ...(addWaterMark && {
          ...commonTransformationParam,
          gravity: 'center',
          overlay: 'WM',
        }),
      },
    ],
  });

  return cloudinaryURL?.replace(
    `https://res.cloudinary.com/${process.env.GATSBY_CLOUDINARY_CLOUD_NAME}`,
    process.env.GATSBY_CLOUDINARY_CDN_URL
  );
};

const generateCloudinaryFluidImage = (
  maxWidth,
  public_id,
  imageWidth = 1280,
  height = 805,
  quality = 90,
  addWaterMark = false
) => {
  const aspectRatio = imageWidth / height;
  const sizes = `(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`;
  const src = generateCloudinaryUrl(
    public_id,
    quality,
    _.min([maxWidth, imageWidth]),
    'auto',
    addWaterMark
  );
  let srcSet = '';
  const widthRatiosArray = [0.25, 0.5, 0.75, 1];

  const isBrowser = typeof window !== `undefined`;
  widthRatiosArray.forEach(r => {
    const width = Math.ceil(r * maxWidth);
    if (width <= imageWidth) {
      if (isBrowser) {
        srcSet += `${generateCloudinaryUrl(
          public_id,
          quality,
          width,
          window.innerWidth < 600 ? '' : 'auto',
          addWaterMark
        )} ${width}w,`;
      } else {
        srcSet += `${generateCloudinaryUrl(
          public_id,
          quality,
          width,
          'auto',
          addWaterMark
        )} ${width}w,`;
      }
    }
  });
  if (imageWidth < maxWidth)
    srcSet += `${generateCloudinaryUrl(
      public_id,
      quality,
      imageWidth,
      'auto',
      addWaterMark
    )} ${imageWidth}w`;

  const base64 = generateCloudinaryUrl(public_id, 10, 10);
  return {
    aspectRatio,
    src,
    srcSet,
    sizes,
    base64,
  };
};

const combineCloudinaryWithImages = (
  vehicle,
  maxWidth = 1800,
  limit,
  quality,
  addWaterMark = false
) => {
  const cloudinaryImages = [];
  let allCloudinaryImages = [
    ...(vehicle?.cloudinaryImages ?? vehicle?.item?.cloudinaryImages ?? []),
    ...(vehicle?.cloudinaryImagesCombined ??
      vehicle?.item?.cloudinaryImagesCombined ??
      []),
    ...(vehicle?.item?.cloudinaryImages1 ?? []),
    ...(vehicle?.item?.cloudinaryImages2 ?? []),
    ...(vehicle?.item?.cloudinaryImages3 ?? []),
    ...(vehicle?.item?.cloudinaryImages4 ?? []),
    ...(vehicle?.item?.cloudinaryImages5 ?? []),
    ...(vehicle?.item?.cloudinaryImages6 ?? []),
  ];

  if (allCloudinaryImages?.length > 0) {
    if (limit) allCloudinaryImages = allCloudinaryImages.slice(0, limit);
    allCloudinaryImages.forEach(({ public_id, width, height, context }) => {
      cloudinaryImages.push({
        fluid: generateCloudinaryFluidImage(
          maxWidth,
          public_id,
          width,
          height,
          quality,
          addWaterMark
        ),
        title: vehicle.item?.title ?? vehicle.title,
        alt: context?.custom?.alt,
      });
    });
  }

  return cloudinaryImages;
};

const generateContentfulAssetURL = url => {
  if (!url) return ``;
  return `https:${url}`;
};
export {
  generateFluidImage,
  generateCloudinaryFluidImage,
  combineCloudinaryWithImages,
  generateCloudinaryUrl,
  generateContentfulAssetURL,
};
